.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #f5f5f5; /* Couleur de fond de l'en-tête */
  height: 120px; /* Hauteur de l'en-tête */
  position: relative;
}

.left-container,
.right-container {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 5000;
}

.left-container {
  left: 15%; /* Positionné proportionnellement à gauche */
}

.right-container {
  right: 15%; /* Positionné proportionnellement à droite */
}

.left-image,
.right-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  position: relative;
  border-radius: 50%; /* Optionnel si vous souhaitez un cercle */
}

.left-image {
  left: 60px; /* Débordement à gauche */
}

.right-image {
  right: -60px; /* Débordement à droite */
}

.left-box,
.right-box {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px 15px;
  margin: 0 40px; /* Décalage pour éviter que l'image ne couvre */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100px;
  height: 40px;
}

.left-text,
.right-text {
  margin-left: 50px; /* Décalage interne du texte */
  font-size: 24px;
  font-weight: bold;
  color: #6A92D7;
}

.right-text {
  color: red;
}

.center-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.center-box {
  background-color: #6A92D7; /* Bleu */
  border-radius: 50px;
  width: 20%; /* 20% de la largeur du parent */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 100px;
}

.center-main-text {
  color: white;
  font-size: 52px;
  font-weight: bold;
  text-align: center;
  width: 80%; /* 80% de la largeur */
  height: 80%; /* 80% de la hauteur */
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-sub-text {
  font-size: 18px;
  text-align: center;
  width: 60%; /* 60% de la largeur */
  height: 15%; /* 15% de la hauteur */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px; /* Petit espacement avec le texte principal */
}
